export const formatArrayForSelectInput = (arrData, display, isNullable = false) => {
    let arrayToDisplay = [];
    arrayToDisplay = arrData.map((obj, i) => {
        const objToDisplay = {
            value: obj.id,
            display: display.includes("~") ? getMultipleObjDisplays(obj, display) : getObjDisplay(obj, display)
        }
        return objToDisplay;
    });
    if (isNullable) {
        arrayToDisplay.unshift({
            value: null,
            display: ''
        })
    }
    return arrayToDisplay;
}

const getObjDisplay = (obj, display) => {
    let returnVal = null;
    if (display.includes(".")) {
        let splitArr = display.split(".");
        returnVal = obj[splitArr[0]];

        for (let i = 1; i < splitArr.length; i++) {
            returnVal = returnVal[splitArr[i]];
        }
    } else {
        returnVal = obj[display];
    }

    return returnVal;
}

const getMultipleObjDisplays = (obj, display) => {
    let objKeys = display.split("~");
    let returnVal = getObjDisplay(obj, objKeys[0]);

    for (let i = 1; i < objKeys.length; i++) {
        returnVal += `, ${getObjDisplay(obj, objKeys[i])}`
    }

    return returnVal;
}