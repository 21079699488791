import React, { useEffect, useState } from "react";
import { getWithExpiry } from "../../../Domain/Helpers/LocalStorageHelper";
import { getTransactions } from "../../../Infrastructure/Services/TransactionService";
import UISearchBar from "../../../Domain/Components/UI/SearchBar/SearchBar";
import UITable from "../../../Domain/Components/UI/Table/Table";
import UIButton from "../../../Domain/Components/UI/Button/Button";
import { styleType } from "../../../Domain/Constants/StyleType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward, faForward } from "@fortawesome/free-solid-svg-icons";

function TransactionListPage(props) {
    const [isAdmin, setIsAdmin] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(false);
    const limit = 100;
    const [search, setSearch] = useState(null);
    const [all, setAll] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let roles = getWithExpiry("roles");

        if (!roles) {
            props.setLoggedIn(false);
            return;
        }

        if (roles.filter(x => x.includes('super-admin')).length > 0) {
            setIsAdmin(true);
        }
    }, [])

    const loadTransactions = async () => {
        const token = getWithExpiry("token");
        if (!token) {
            props.setLoggedIn(false);
            return;
        }
        let response = null;
        if (isAdmin) {
            response = await getTransactions(token, (page - 1) * limit, limit, all, search);
        } else {
            response = await getTransactions(token, (page - 1) * limit, limit);
        }

        if (response) {
            if (response.length > 0 || page === 1) {
                setTransactions(response);
            }

            if (response.length < limit) {
                setLastPage(true);
            }

            
            if (response.length === 0 && page !== 1) {
                setPage(page - 1);
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        loadTransactions().then(() => {
            setLoading(false)
        });
    }, [page]);

    useEffect(() => {
        if (page === 1) {
            setLoading(true);
            loadTransactions().then(() => {
                setLoading(false)
            });
        } else {
            setPage(1);
        }
    }, [search, all]);

    const onSearchInputChange = (e) => {
        const inputVal = e.target.value;
        if (!inputVal || inputVal === "") {
            setSearch(null);
        }
    }

    const allCheckChaned = (e) => {
        setAll(e.target.checked);
    }

    const goOnPrevPage = () => {
        if (page === 1) return;

        setPage(page - 1);

        if (lastPage) setLastPage(false);
    }

    return (
        <div className="container">
            <div className="d-flex justify-content-between mt-2">
                <h3>Transactions</h3>
                <div>
                    {isAdmin &&
                        <div className="d-flex">
                            <div className="form-check mt-2 me-2">
                                <input className="form-check-input" type="checkbox" id="all-check" onChange={allCheckChaned} />
                                <label className="form-check-label" htmlFor="all-check">
                                    All transactions
                                </label>
                            </div>
                            <UISearchBar onSearch={(val) => setSearch(val)} onInputChange={onSearchInputChange} />
                        </div>}

                </div>
            </div>

            <div className="mt-2">
            {transactions === null ?
                <p className="text-danger">Failed to load transactions</p>  :
                loading ? 
                <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div> : 
                        transactions.length === 0 ? <span className="text-secondary">No transaction found</span> :
                        <>
                <UITable
                    columnTitles={!isAdmin || (isAdmin && !all && search === null) ? 
                        ["ID", "Game Name", "Provider Round ID", "Session ID", "Amount", "Promotion ID", "Type", "Status", "Date Created"] : 
                        ["ID", "Game Name", "Player ID", "Player Username", "Provider Round ID", "Session ID", "Amount", "Promotion ID", "Type", "Status", "Date Created"]
                    }
                    data={transactions}
                    objectKeys={!isAdmin || (isAdmin && !all && search === null) ? 
                        ["id", "Session.GameConfig.name", "Round.provider_round", "session_id", "amount", "promotion_id", "type", "status", "created_at"] : 
                        ["id", "Session.GameConfig.name", "Session.PlayerCurrency.player_id", "Session.PlayerCurrency.player.username", "Round.provider_round", "session_id", "amount", "promotion_id", "type", "status", "created_at"]
                    }
                    primaryKey='id'
                    preventDelete={true}
                />
                <div>
                    <div className="d-flex justify-content-between mt-2">
                        <UIButton styleType={styleType.FADEBLACK} disabled={page === 1} onClick={() => goOnPrevPage()} 
                        icon={<FontAwesomeIcon icon={faBackward} />} />

                        <h5>{page}</h5>

                        <UIButton styleType={styleType.FADEBLACK} disabled={lastPage} onClick={() => setPage(page + 1)} 
                        icon={<FontAwesomeIcon icon={faForward} />} />
                    </div>
                </div>
                </>
            }
            </div>
        </div>
    )
}

export default TransactionListPage;