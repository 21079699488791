import { useEffect, useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceSix, faGear, faUser, faHandHoldingDollar, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import UISidebar from '../Domain/Components/UI/Sidebar/Sidebar';
import UINavbar from '../Domain/Components/UI/Navbar/Navbar';
import { ToastContainer } from 'react-toastify';
import logo from './Assets/Images/flexGamesLogo.png'
import { getWithExpiry } from '../Domain/Helpers/LocalStorageHelper';
import LoginPage from './Pages/Login/LoginPage';
import CreateBalancePage from './Pages/Balance/CreateBalance';
import GameListPage from './Pages/Game/GameList/GameListPage';
import SelectBalancePage from './Pages/Balance/SelectBalance';
import GamePlayPage from './Pages/Game/GamePlay/GamePlayPage';
import TransactionListPage from './Pages/Transaction/TransactionList';
import SelectLanguagePage from './Pages/Language/SelectLanguage';
import CreatePlayerPage from './Pages/Player/CreatePlayer';
import CreateGamePage from './Pages/Game/GameCreate/GameCreate';
import AssignRolePage from './Pages/Role/AssignRole';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(getWithExpiry("token") !== null);
  const [sidebarContents, setSidebarContents] = useState([]);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  } 

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loggedIn) {
      let hideAdminSection = false;
      let roles = getWithExpiry("roles");
      if (!roles) {
        setLoggedIn(false);
        return;
      }
      if (roles.filter(x => x.includes('super-admin')).length === 0) {
        hideAdminSection = true;
      }
      setSidebarContents([
        {
          submenuTitle: "Gaming",
          submenuIcon: <FontAwesomeIcon icon={faDiceSix} />,
          contents: [
            {
              linkEl: <Link to="/game">Games</Link>,
              path: "/game"
            },
            {
              linkEl: <Link to="/transaction">Transactions</Link>,
              path: "/transaction"
            }
          ]
        },
        {
          submenuTitle: "Balance",
          submenuIcon: <FontAwesomeIcon icon={faHandHoldingDollar} />,
          contents: [
            {
              linkEl: <Link to="/balance/create">Create Balance</Link>,
              path: "/balance/create"
            },
            {
              linkEl: <Link to="/balance/select">Select Balance</Link>,
              path: "/balance/select"
            }
          ]
        },
        {
          submenuTitle: "Language",
          submenuIcon: <FontAwesomeIcon icon={faLanguage} />,
          contents: [
            {
              linkEl: <Link to="/language/select">Select Language</Link>,
              path: "/language/select"
            }
          ]
        },
        {
          submenuTitle: "Admin",
          submenuIcon: <FontAwesomeIcon icon={faGear} />,
          hidden: hideAdminSection,
          contents: [
            {
              linkEl: <Link to="/game/create">Create Game</Link>,
              path: "/game/create"
            },
            {
              linkEl: <Link to="/player/create">Create Player</Link>,
              path: "/player/create"
            },
            {
              linkEl: <Link to="/role/assign">Assign Role</Link>,
              path: "/role/assign"
            }
          ],
        },
        {
          submenuTitle: "Account",
          submenuIcon: <FontAwesomeIcon icon={faUser} />,
          contents: [
            {
              linkEl: <button onClick={() => logout()}>Logout</button>,
              path: null
            }
          ]
        }
      ]);

      if(window.location.href.includes("login")) {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        let returnUrl = params.get('returnUrl');
        navigate(returnUrl ? returnUrl : "/games");
      }
    } else {
      setSidebarContents([
        {
          submenuTitle: "Account",
          submenuIcon: <FontAwesomeIcon icon={faUser} />,
          contents: [
            {
              linkEl: <Link to="/login">Login</Link>,
              path: "/login"
            }
          ]
        }
      ]);
      localStorage.removeItem("roles");
      if (!location.pathname.includes("login"))
        navigate(`/login?returnUrl=${location.pathname}${location.search}`);
    }
  }, [loggedIn])

  const logout = async() => {
    localStorage.removeItem("token");
    setLoggedIn(false);
  }

  return (
    <div id="wrapper" className={`${sidebarOpen ? 'toggled' : ''}`}>
    <ToastContainer position="bottom-right" 
                  autoClose={10000}
                  theme="dark"
                  />
    <div className="overlay"></div>

    <UISidebar contents={sidebarContents} logoSrc={logo}/>
    <div id="page-content-wrapper">
      <UINavbar toggleSidebar={toggleSidebar}/>
      <div className="container app-container">
        <Routes>
          <Route path="/" element={<></>} />

          <Route path="/login" element={<LoginPage setLoggedIn={setLoggedIn} />} />

          <Route path="/balance/create" element={<CreateBalancePage setLoggedIn={setLoggedIn} />} />
          <Route path='/balance/select' element={<SelectBalancePage setLoggedIn={setLoggedIn} />} />

          <Route path='/language/select' element={<SelectLanguagePage setLoggedIn={setLoggedIn} />} />

          <Route path="/game" element={<GameListPage setLoggedIn={setLoggedIn} />} />
          <Route path="/game/play" element={<GamePlayPage setLoggedIn={setLoggedIn} />} />
          <Route path="/game/create" element={<CreateGamePage setLoggedIn={setLoggedIn} />} />

          <Route path='/transaction' element={<TransactionListPage setLoggedIn={setLoggedIn} />} />

          <Route path='/player/create' element={<CreatePlayerPage setLoggedIn={setLoggedIn} />} />

          <Route path='/role/assign' element={<AssignRolePage setLoggedIn={setLoggedIn} />} />
        </Routes>
      </div>
    </div>

  </div>
  );
}

export default App;
